import React, { useState } from "react";
import { useAuth } from "react-oidc-context";
import { useTranslation } from "react-i18next";
import YesNoConfirmationDialog from "../YesNoConfirmationDialog";
import config from "../../../app/config";
import { deleteDocument } from "../../../app/singledoc/apiCalls";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import DelayInfo from "../DelayInfo";

const DeleteDocumentDialog = ({ requestId, seqNo, primaryTerm, open, onClose, nextLocation, onError }) => {
    const { user } = useAuth();
    const { t } = useTranslation("document");
    const navigate = useNavigate();
    const [confirm, setConfirm] = useState(true);
    function closeDialog() {
        onClose();
        setConfirm(true);
    }
    return (
        <YesNoConfirmationDialog
            open={open}
            showActions={confirm}
            okClicked={() => {
                deleteDocument(requestId, seqNo, primaryTerm, user)
                    .then(resp => {
                        if (!resp.ok) {
                            throw new Error(`Deletion failed with ${resp.status}`);
                        }
                        setTimeout(() => {
                            closeDialog();
                            navigate(nextLocation)
                        }, config.documents.deletionDelay);
                    })
                    .catch(e => {
                        closeDialog();
                        onError(e)
                    })
                setConfirm(false);
            }}
            noClicked={() => {
                onClose();
            }}
            title={t("Remove")}>
            {confirm && t("Removal Confirmation")}
            {!confirm && <DelayInfo title={t("Removal Delay")} />}
        </YesNoConfirmationDialog>
    );
};
DeleteDocumentDialog.propTypes = {
    requestId: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    nextLocation: PropTypes.string.isRequired,
    onError: PropTypes.func.isRequired,
    seqNo: PropTypes.string.isRequired,
    primaryTerm: PropTypes.string.isRequired
}
export default DeleteDocumentDialog;
