import React from 'react';
import {FieldArray, FormikProvider} from "formik";
import {Box, Chip, FormLabel, InputAdornment, Paper, TextField} from "@mui/material";
import {AddCircle} from "@mui/icons-material";
import {styled} from '@mui/material/styles';
import PropTypes from "prop-types";

const ItemsContainer = styled(Paper)(({ theme }) => ({
    marginTop: "20px"
}));

const StyledChip = styled(Chip)(({ theme }) => ({
    margin: "5px"
}));

const NoChipBox = styled(Box)(({ theme }) => ({
    margin: "10px",
    padding: "10px"
}));

const ItemsChipSetFormInput = ({
                                   formik,
                                   entryLabel,
                                   entryValue,
                                   entryFieldName,
                                   itemsName,
                                   itemsValue,
                                   noItemsLabel
                               }) => {

    const {setFieldValue, handleChange} = formik;

    const pushItem = (e, arrayHelpers) => {
        if ((e.type === "keydown" && e.key === "Enter") || e.type === "click") {
            e.preventDefault();
            if (entryLabel === "") {
                return;
            }
            arrayHelpers.push(entryValue);
            setFieldValue(entryFieldName, "");
        }
    }

    return (
        <FormikProvider value={formik}>
            <FieldArray name={itemsName} render={arrayHelpers => {
                return (<>
                    <TextField
                        label={entryLabel}
                        name={entryFieldName}
                        value={entryValue}
                        onKeyDown={e => pushItem(e, arrayHelpers)}
                        onChange={handleChange}
                        InputProps={{
                            startAdornment:
                                <InputAdornment onClick={e => pushItem(e, arrayHelpers)}
                                                position="start">
                                    <AddCircle/>
                                </InputAdornment>
                        }}
                    />
                    <ItemsContainer>
                        {itemsValue && itemsValue.length > 0 ?
                            itemsValue.map((iv, index) =>
                                <StyledChip
                                      key={index}
                                      label={iv}
                                      onDelete={() => arrayHelpers.remove(index)}
                                      color="primary"/>)
                            : <NoChipBox>
                                <FormLabel>{noItemsLabel}</FormLabel>
                            </NoChipBox>
                        }
                    </ItemsContainer>
                </>)
            }}/>
        </FormikProvider>
    );
};

ItemsChipSetFormInput.propTypes = {
    formik: PropTypes.object.isRequired,
    entryLabel: PropTypes.string.isRequired,
    entryValue: PropTypes.string.isRequired,
    entryFieldName: PropTypes.string.isRequired,
    itemsName: PropTypes.string.isRequired,
    itemsValue: PropTypes.array.isRequired,
    noItemsLabel: PropTypes.string.isRequired
}

export default ItemsChipSetFormInput;
