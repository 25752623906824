import React, {useState} from "react";
import Panel from "../container/Panel";
import {useTranslation} from "react-i18next";
import {Chip, Grid, Button, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import moment from "moment";
import MinioDownloadLinksProvider from "../components/minio/MinioDownloadLinksProvider";
import MinioDownloadLinks from "../components/minio/MinioDownloadLinks";
import FormElementLoading from "../components/FormElementLoading";
import {useSingleDocument} from "../../app/singledoc/hooks";
import {DeleteForever, EditRounded} from "@mui/icons-material";
import DeleteDocumentDialog from "../components/document/DeleteDocumentDialog";
import InfoSnackbar from "../components/InfoSnackbar";
import {useNavigate, useParams} from "react-router-dom";
import GMapsLink from "../components/GMapsLink";

const DATE_FORMAT = "YYYY-MM-DD";

const ChipsDisplay = ({items}) => (
    items ? (
        <>
        {items.map((item, index) => (
            <Chip key={index} sx={{margin: "2px"}} label={item} />
        ))}
        </>
    ) : null
);

const ValTableCell = ({children}) => <TableCell><Typography variant="body1">{children}</Typography></TableCell>;

const singleStringOrEnumArray = (value, translations, prefix) => {
    if (Array.isArray(value)) {
        return value.map(element => translations(`${prefix}.${element}`)).join(", ")
    } else {
        return translations(`${prefix}.${value}`)
    }
}

const SingleDocument = () => {
    const {t} = useTranslation("document");
    const {t: tForm} = useTranslation("uploadForm")
    let params = useParams()
    const {doc, error} = useSingleDocument(params.id)
    const displayDate = date => (date ? moment(date).format(DATE_FORMAT) : "")
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [infoShown, setInfoShown] = useState(false)
    const navigate = useNavigate()

    return (<Panel title={t("Page Title")}>
        {doc && !error &&
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Grid container direction="column">
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{tForm("Title")}</TableCell>
                                    <ValTableCell>{doc.title}</ValTableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{tForm("Document Type")}</TableCell>
                                    <ValTableCell>{tForm(`documentTypes.${doc.documentType}`)}</ValTableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{tForm("Language")}</TableCell>
                                    <ValTableCell>{tForm(`languages.${doc.lang}`)}</ValTableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{tForm("Document author")}</TableCell>
                                    <ValTableCell>{doc.author}</ValTableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{tForm("Addressed to")}</TableCell>
                                    <ValTableCell>{doc.addressedTo}</ValTableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{tForm("Document creation date")}</TableCell>
                                    <ValTableCell>{displayDate(doc.createdOn)}</ValTableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{tForm("Document number")}</TableCell>
                                    <ValTableCell>{doc.documentNumber}</ValTableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{tForm("Origin")}</TableCell>
                                    <ValTableCell>{doc.origin}</ValTableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{tForm("Archive")}</TableCell>
                                    <ValTableCell>{doc.archive}</ValTableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{tForm("Signature")}</TableCell>
                                    <ValTableCell>{doc.signatureMark}</ValTableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("Keywords")}</TableCell>
                                    <TableCell><ChipsDisplay items={doc.keywords}/></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{tForm("Topic")}</TableCell>
                                    <ValTableCell>{doc.topic}</ValTableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{t("Personalities")}</TableCell>
                                    <TableCell><ChipsDisplay items={doc.importantPersonalities}/></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{tForm("Comments")}</TableCell>
                                    <ValTableCell>{doc.comments}</ValTableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{tForm("Abstract")}</TableCell>
                                    <ValTableCell>{doc.abstract}</ValTableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{tForm("Request Id")}</TableCell>
                                    <ValTableCell>{doc.requestId}</ValTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container direction="column">
                        {doc.optionalMetadata && doc.optionalMetadata.concentrationCamps && <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{tForm('Atrocities Location')}</TableCell>
                                    <ValTableCell>
                                        <GMapsLink location={doc.optionalMetadata.locationOfAtrocities}/>
                                    </ValTableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{tForm("Concentration Camps")}</TableCell>
                                    <ValTableCell>{doc.optionalMetadata.concentrationCamps}</ValTableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{tForm("Atrocities Number")}</TableCell>
                                    <ValTableCell>{doc.optionalMetadata.atrocitiesNumber}</ValTableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{tForm("Atrocities Perpetrators")}</TableCell>
                                    <ValTableCell>{
                                        singleStringOrEnumArray(doc.optionalMetadata.atrocitiesPerpetrators, tForm, "perpetrators")
                                    }</ValTableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{tForm("Atrocities Victims")}</TableCell>
                                    <ValTableCell>{
                                        singleStringOrEnumArray(doc.optionalMetadata.atrocitiesVictims, tForm, "victims")
                                    }</ValTableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{tForm("Atrocities Typology")}</TableCell>
                                    <ValTableCell>{tForm(`atrocities.${doc.optionalMetadata.atrocitiesTypology}`)}</ValTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>}
                        <MinioDownloadLinksProvider requestId={doc.requestId} render={ml =>
                            <MinioDownloadLinks docId={doc.pdf} minioLinks={ml}/>
                        }/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{margin: "20px"}}>
                <Grid item xs={12}>
                    <Button
                        startIcon={<EditRounded />} color="primary" variant="text"
                        onClick={() => navigate(`/edit/${doc.requestId}`, {state: {document: doc}})}
                    >
                        {t("Edit")}
                    </Button>
                    &nbsp;
                    <Button
                        startIcon={<DeleteForever />} color="primary" variant="text"
                        onClick={() => setShowConfirmation(true)}
                    >
                        {t("Remove")}
                    </Button>
                </Grid>
            </Grid>
            <DeleteDocumentDialog
                onError={_ => setInfoShown(true)}
                nextLocation="/search"
                requestId={doc.requestId}
                seqNo={doc.seqNo}
                primaryTerm={doc.primaryTerm}
                open={showConfirmation}
                onClose={() => setShowConfirmation(false)}/>
            <InfoSnackbar
                autoHideDuration={6000}
                open={infoShown}
                handleClose={() => setInfoShown(false)}
                message={t("Delete Failed")}/> {/* TODO Customize message per status code */}
        </>}
        {!doc && !error && (
            <Grid container>
                <Grid item xs={1}><FormElementLoading/></Grid>
            </Grid>
        )}
        {error && <div>{t(error, "Unexpected error returned from backend!")}</div>}
    </Panel>);
};

export default SingleDocument;
