import React from 'react';
import {MenuItem, TextField, styled} from "@mui/material";
import FormElementLoading from "./FormElementLoading";

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    textAlign: 'left'
  }
});

const SelectOption = (props) => {
    const {data, isLoading, ...rest} = props;
    return (isLoading ?
        <FormElementLoading/> :
        <StyledTextField type="text" select {...rest}>
            {data.map(option => (<MenuItem key={option.key} value={option.key}>{option.label}</MenuItem>))}
        </StyledTextField>);
};

export default SelectOption;
