import React from 'react';
import {FormHelperText, OutlinedInput, MenuItem, Select} from "@mui/material";
import FormElementLoading from "./FormElementLoading";
import { styled } from "@mui/material/styles";

const StyledSelect = styled(Select)(() => ({
    '&.pos': {
        textAlign: 'left'
    },
    '&.error': {
        textAlign: 'left',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red'
        },
        color: 'red',
        "& span": {
            color: 'red'
        }
    },
    '& .emptyLabel': {
        color: 'gray'
    }
}));

const MultiSelectOption = (props) => {
    const {data, isLoading, label, error, helperText, ...rest} = props;
    return (isLoading ?
            <FormElementLoading/> :
            <>
                <StyledSelect
                    className={error ? 'error' : 'pos'}
                    multiple
                    displayEmpty
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return <span className="emptyLabel">{label}</span>
                        }
                        return selected.map(sel => data.find(opt => opt.key === sel).label).join(', ')
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                    {...rest}>
                    <MenuItem disabled value="">
                        <span>{label}</span>
                    </MenuItem>
                    {data.map(option => (<MenuItem key={option.key} value={option.key}>{option.label}</MenuItem>))}
                </StyledSelect>
                { error && <FormHelperText error>{ helperText }</FormHelperText> }
            </>
    )
}

export default MultiSelectOption;
