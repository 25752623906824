import { useState, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import config from "../config";
import { bearerAuthorizationHeader } from "../../auth/util";

const makeDocInfo = (doc, error) => ({ doc, error });

export const useSingleDocument = (id) => {
    const [docInfo, setDocInfo] = useState(makeDocInfo(null, null));
    const { user } = useAuth();
    const token = user?.access_token;

    useEffect(() => {
        if (!docInfo.doc) {
            const { apiGateway: { host } } = config;
            fetch(`${host}/document/metadata/${id}`, bearerAuthorizationHeader(token))
                .then(resp => {
                    if (resp.ok) {
                        return resp.json()
                    } else {
                        throw resp.status.toString()
                    }
                })
                .then(({ primaryTerm, seqNo, metadata }) => setDocInfo(makeDocInfo({
                    requestId: id,
                    seqNo,
                    primaryTerm, ...metadata
                }, null)))
                .catch(error => setDocInfo(makeDocInfo(null, error)))
        }
    }, [id, token, docInfo.doc]);

    return docInfo;
}