import { useRequestStatusDataLoader } from "../../../app/status/hooks";
import PropTypes from "prop-types";
import { useState } from "react";
import { useInterval } from "../../../app/common/hooks";

const RequestStatusDataProvider = ({ render, pageSize, onPageChange }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const data = useRequestStatusDataLoader(currentPage, pageSize, refreshTrigger);
    useInterval(() => setRefreshTrigger(t => t + 1), 3000);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        onPageChange();
    };

    return (
        <>
            {render(data.content, handlePageChange, pageSize, data.totalElements, currentPage)}
        </>
    );
};

RequestStatusDataProvider.propTypes = {
    onPageChange: PropTypes.func.isRequired,
    render: PropTypes.func.isRequired,
    pageSize: PropTypes.number
};

RequestStatusDataProvider.defaultProps = {
    pageSize: 10
}

export default RequestStatusDataProvider;
