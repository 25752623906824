import React from 'react';
import {styled} from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    padding: theme.spacing(1)
}));

const FormElement = ({ children, ...rest }) => {
    return (
        <StyledFormControl {...rest}>
            {children}
        </StyledFormControl>
    );
};

export default FormElement;
