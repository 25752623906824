import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {Paper, styled} from "@mui/material";

const StyledPaper = styled(Paper)(({ theme }) => ({
    maxHeight: 300,
    textAlign: "center"
}));

const YesNoConfirmationDialog = ({open, okClicked, noClicked, title, children, showActions, ...other}) => {
    const {t} = useTranslation("common");
    return (
        <Dialog
            {...other}
            PaperComponent={StyledPaper}
            maxWidth="sm"
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    noClicked();
                }
            }}
            disableEscapeKeyDown
            showActions
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
            {showActions && <DialogActions>
                <Button onClick={okClicked} color="secondary">
                    {t("Yes Button")}
                </Button>
                <Button onClick={noClicked} color="primary" autoFocus>
                    {t("No Button")}
                </Button>
            </DialogActions>}
        </Dialog>
    );
};

YesNoConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    okClicked: PropTypes.func.isRequired,
    noClicked: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    showActions: PropTypes.bool
}

YesNoConfirmationDialog.defaultProps = {
    showActions: true
}

export default YesNoConfirmationDialog;
