import {OpenInNew} from "@mui/icons-material";
import {Link} from "@mui/material";
import React from "react";

const GMapsLink = ({location}) =>
    <Link target="_blank"
          href={`https://www.google.com/maps/place/${location}`}>
        <OpenInNew/>
    </Link>

export default GMapsLink
