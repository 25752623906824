import Grid from "@mui/material/Grid";
import Panel from "../container/Panel";
import {useTranslation} from "react-i18next";
import RequestStatusDataGrid from "../components/status/RequestStatusDataGrid";
import RequestStatusDataProvider from "../components/status/RequestStatusDataProvider";
import {styled} from "@mui/material/styles";
import {Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {Done, Error, Help, ReportProblem} from "@mui/icons-material";
import {useState} from "react";

const Root = styled('div')(({theme}) => ({
    height: '100%',
    [theme.breakpoints.up('md')]: {
        // Styles for screens with a width of 960px and above
        display: 'flex',
        flexDirection: 'column',
    },
}));

const PanelContainer = styled(Grid)(({theme}) => ({
    [theme.breakpoints.up('md')]: {
        // Styles for screens with a width of 960px and above
        flexDirection: 'row',
    },
}));

const Home = () => {
    const {t} = useTranslation("status");
    const [errorInfo, setErrorInfo] = useState(null);

    const showErrorInfo = row => {
        setErrorInfo(row.requestStatusValue === "ERROR" ? row.errorMessage : null);
    }

    return (
        <PanelContainer container>
            <Grid item xs={12} md={10}>
                <Panel title={t("Page Title")}>
                    <Root>
                        <RequestStatusDataProvider
                            onPageChange={() => setErrorInfo(null)}
                            render={(data, handlePageChange, pageSize, totalElements, currentPage) => (
                                <RequestStatusDataGrid
                                    onRowClick={showErrorInfo}
                                    pageSize={pageSize}
                                    page={currentPage}
                                    onPageChange={handlePageChange}
                                    totalElements={totalElements}
                                    data={data}/>)}
                        />
                    </Root>
                </Panel>
                {errorInfo &&
                <Panel title={t("Error Info")}>
                    <Typography variant="body1" color="error" sx={{textAlign: "left"}}>
                        {errorInfo}
                    </Typography>
                </Panel>}
            </Grid>
            <Grid item xs={12} md={2}>
                <Panel title={t("Info")}>
                    <div>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell><Done/></TableCell>
                                    <TableCell>{t("Done")}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Error/></TableCell>
                                    <TableCell>{t("Error")}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Help/></TableCell>
                                    <TableCell>{t("Non existent")}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><ReportProblem/></TableCell>
                                    <TableCell>{t("Unknown")}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </Panel>
            </Grid>
        </PanelContainer>
    );
};

export default Home;
