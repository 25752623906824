import React from 'react'
import {LinearProgress, Typography} from "@mui/material";

const DelayInfo = ({title}) => (
    <Typography variant="h6" color="primary">
        {title}
        <LinearProgress/>
    </Typography>
)

export default DelayInfo;
