import { useState, useEffect } from "react";
import config from "../config";
import { useAuth } from "react-oidc-context";
import { bearerAuthorizationHeader } from "../../auth/util";

export function useJsonFrom(path) {
    const [state, setState] = useState({ loading: true, response: {}, error: false });
    const { user } = useAuth();
    const token = user?.access_token;
    useEffect(() => {
        async function loadJson() {
            const { host } = config.apiGateway;
            const response = await fetch(host + path, bearerAuthorizationHeader(token));
            const body = await response.json();
            setState({ loading: false, response: body, error: false });
        }

        loadJson().catch(() => setState({ loading: false, response: {}, error: true }));
    }, [path, token]);
    return state;
}

