import { styled } from "@mui/material/styles";
import { AppBar, CssBaseline, Grid, MenuItem, Toolbar } from "@mui/material";
import { AuthProvider, useAuth, withAuthenticationRequired } from "react-oidc-context";
import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, Routes, Route, Outlet } from "react-router-dom";
import Home from "./ui/pages/Home";
import Upload from "./ui/pages/Upload";
import Search from "./ui/pages/Search";
import { oidcConfig } from "./auth/oidc-config";
import './i18n'
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import SingleDocument from "./ui/pages/SingleDocument";
import FormElementLoading from "./ui/components/FormElementLoading";
import Edit from "./ui/pages/Edit";

const Root = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
}));

const StyledToolbar = styled(Toolbar)(({ theme, isDesktop }) => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    justifyContent: isDesktop ? 'space-between' : 'flex-start'
}));

const StyledMenuItem = styled(MenuItem)(({ isDesktop }) => ({
    marginLeft: isDesktop ? 'auto' : 'inherit'
}));

const NavContainer = styled(Grid)(({ theme }) => ({
    marginBottom: theme.spacing(2)
}));

const AuthButton = props => {
    const auth = useAuth();
    return (
        auth.isAuthenticated &&
        <StyledMenuItem
            {...props}
            color="inherit"
            onClick={() => auth.signoutRedirect()}>
            {props.isDesktop ? `Logout [${auth.user?.profile?.preferred_username}]` : 'Logout'}
        </StyledMenuItem>
    );
}

const MenuLayout = ({ isDesktop }) => {
    return (
        <Root>
            <NavContainer container>
                <Grid item xs={12}>
                    <AppBar position="static">
                        <StyledToolbar isDesktop={isDesktop}>
                            <MenuItem component={Link} to="/">Home</MenuItem>
                            <MenuItem component={Link} to="/search">Search</MenuItem>
                            <MenuItem component={Link} to="/upload">Upload</MenuItem>
                            <AuthButton isDesktop={isDesktop} />
                        </StyledToolbar>
                    </AppBar>
                </Grid>
            </NavContainer>
            <Outlet />
        </Root>
    );
};

const ProtectedLayout = withAuthenticationRequired(({ isDesktop }) => (
    <MenuLayout isDesktop={isDesktop}>
        <Outlet />
    </MenuLayout>
), {
    onRedirecting: () => <FormElementLoading />
});

const App = () => {
    const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const isDesktopScreen = window.matchMedia('(min-width: 768px)').matches;
            setIsDesktop(isDesktopScreen);
        };
        handleResize(); // Call the handler initially
        window.addEventListener('resize', handleResize); // Add event listener for resizing
        return () => {
            window.removeEventListener('resize', handleResize); // Clean up the event listener
        };
    }, []);

    return (
        <Suspense fallback={<FormElementLoading />}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <AuthProvider {...oidcConfig}>
                    <CssBaseline />
                    <Router>
                        <Routes>
                            <Route element={<ProtectedLayout isDesktop={isDesktop} />}>
                                <Route path="/" element={<Home />} />
                                <Route path="/upload" element={<Upload />} />
                                <Route path="/search" element={<Search />} />
                                <Route path="/document/:id" element={<SingleDocument />} />
                                <Route path="/edit/:id" element={<Edit />} />
                            </Route>
                        </Routes>
                    </Router>
                </AuthProvider>
            </LocalizationProvider>
        </Suspense>
    );
}

export default App;