import React from 'react';
import FormElement from "./FormElement";
import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

const LoadingWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
});

const FormElementLoading = () => {
    return (
        <FormElement>
            <LoadingWrapper>
                <CircularProgress />
            </LoadingWrapper>
        </FormElement>
    );
};

export default FormElementLoading;