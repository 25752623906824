import React, { useState } from "react";
import { useFormik } from "formik";
import { useAuth } from "react-oidc-context";
import * as yup from "yup";
import moment from "moment";
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
    useMediaQuery,
    Alert,
    Chip
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { ReactOsmGeocoding } from "@paraboly/react-osm-geocoding"
import "@paraboly/react-osm-geocoding/dist/index.css"
import FormElement from "./FormElement";
import SelectOption from "./SelectOption";
import { useJsonFrom } from "../../app/uploadform/hooks";
import { DatePicker } from "@mui/x-date-pickers";
import ItemsChipSetFormInput from "./ItemsChipSetFormInput";
import FilesDropZone from "./FilesDropZone";
import { uploadForm } from "../../app/uploadform/apiCalls";
import { useNavigate } from "react-router-dom";
import DelayInfo from "./DelayInfo";
import UploadFailedDialog from "./document/UploadFailedDialog";
import { useTranslation } from "react-i18next";
import { addFilesFunctionWith, removeFileFunctionWith } from "../../app/uploadform/fileUploadFunctions"
import MultiSelectOption from "./MultiSelectOption";
import GMapsLink from "./GMapsLink";

const defaultValues = {
    title: "",
    documentType: "",
    lang: "",
    author: "",
    addressedTo: "",
    createdOn: null,
    documentNumber: "",
    origin: "",
    archive: "",
    signatureMark: "",
    keyword: "",
    keywords: [],
    topic: "",
    abstract: "",
    importantPersonality: "",
    importantPersonalities: [],
    comments: "",
    pageImageFiles: [],
    addAdditionalMetadata: false,
    editMode: false,
    locationOfAtrocities: "",
    concentrationCamps: "",
    atrocitiesNumber: "",
    atrocitiesPerpetrators: [],
    atrocitiesVictims: [],
    atrocitiesTypology: ""
}

const FormContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "left",
    "& form": {
        width: "100%"
    }
}));

const BulletSpan = styled('span')(({ theme }) => ({
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
    fontSize: 14,
}));

const PosTypography = styled(Typography)(({ theme }) => ({
    textAlign: 'left',
}));

const makeYupStringRequiredForAdditionalMetadata = msg =>
    yup.string().when("addAdditionalMetadata", {
        is: true,
        then: (schema) => schema.required(msg)
    })

const makeYupArrayRequiredForAdditionalMetadata = msg =>
    yup.array().when("addAdditionalMetadata", {
        is: true,
        then: (schema) => schema.min(1, msg)
    })


const prepareFormData = doc => {
    return {
        ...doc,
        editMode: true,
        addAdditionalMetadata: doc.optionalMetadata && !!doc.optionalMetadata.concentrationCamps,
        atrocitiesNumber: doc.optionalMetadata.atrocitiesNumber,
        atrocitiesPerpetrators: doc.optionalMetadata.atrocitiesPerpetrators,
        atrocitiesVictims: doc.optionalMetadata.atrocitiesVictims,
        atrocitiesTypology: doc.optionalMetadata.atrocitiesTypology,
        locationOfAtrocities: doc.optionalMetadata.locationOfAtrocities,
        concentrationCamps: doc.optionalMetadata.concentrationCamps
    }
}

const UploadForm = ({ t, referenceDataApiPath, doc }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const validationSchema = yup.object({
        title: yup
            .string(t("Title"))
            .required(t("Title is required")),
        documentType: yup
            .string("t(Type of document)")
            .required(t("Document type required")),
        lang: yup
            .string(t("Language"))
            .required(t("Language required")),
        author: yup
            .string(t("Document author"))
            .nullable(),
        addressedTo: yup
            .string(t("Addressed to"))
            .nullable(),
        documentNumber: yup
            .string(t("Document number"))
            .nullable(),
        origin: yup
            .string(t("Origin"))
            .nullable(),
        archive: yup
            .string(t("Archive"))
            .required(t("Archive required")),
        signatureMark: yup
            .string(t("Signature"))
            .required(t("Signature required")),
        abstract: yup
            .string(t("Abstract"))
            .nullable(),
        pageImageFiles: yup.array().when("editMode", {
            is: true,
            then: (schema) => schema.min(1, t("File required"))
        }),
        locationOfAtrocities: makeYupStringRequiredForAdditionalMetadata(t('Atrocities location required')),
        concentrationCamps: makeYupStringRequiredForAdditionalMetadata(t('Concentration camps required')),
        atrocitiesNumber: makeYupStringRequiredForAdditionalMetadata(t('Concentration camps required')),
        atrocitiesPerpetrators: makeYupArrayRequiredForAdditionalMetadata(t('Atrocities perpetrators required')),
        atrocitiesVictims: makeYupArrayRequiredForAdditionalMetadata(t('Atrocities victims required')),
        atrocitiesTypology: makeYupStringRequiredForAdditionalMetadata(t('Atrocities typology required'))
    });
    const [uploadKey, setUploadKey] = useState(0);
    const [showUploadError, setShowUploadError] = useState(false);
    const [fullLocationData, setFullLocationData] = useState(null)
    const navigate = useNavigate();
    const { user } = useAuth();
    const initialValues = !!doc ? prepareFormData(doc) : defaultValues
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            uploadForm(user, values).then(resp => {
                if (resp.ok) {
                    setShowUploadError(false)
                    setUploadKey(prevKey => prevKey + 1);
                    if (values.editMode) {
                        navigate(`/document/${doc.requestId}`)
                    } else {
                        navigate("/"); // TODO: make sure to have some state for handling success and errors etc
                    }
                } else {
                    throw new Error(resp.statusText);
                }
            }).catch(_ => {
                setSubmitting(false);
                setShowUploadError(true);
            })
        }
    });

    const {
        values,
        handleChange,
        handleSubmit,
        touched,
        errors,
        isSubmitting,
        setSubmitting,
        setFieldValue,
        setTouched
    } = formik;

    const {
        loading, response: {
            documentTypes = [],
            languages = [],
            atrocities = [],
            perpetrators = [],
            victims = []
        }
    } = useJsonFrom(referenceDataApiPath)
    const { t: tValidation } = useTranslation("validation")

    return (
        <FormContainer>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={isMobile ? 2 : 3}>
                    <Grid item xs={12} sm={6}>
                        <Grid container direction="column">
                            <FormElement>
                                <TextField
                                    label={t("Title")}
                                    name="title"
                                    value={values.title}
                                    onChange={handleChange}
                                    error={touched.title && Boolean(errors.title)}
                                    helperText={touched.title && errors.title}
                                />
                            </FormElement>
                            <FormElement>
                                <SelectOption
                                    isLoading={loading}
                                    data={documentTypes.map(dt => ({ key: dt, label: t(`documentTypes.${dt}`) }))}
                                    name="documentType"
                                    label={t("Document Type")}
                                    value={values.documentType}
                                    onChange={handleChange}
                                    error={touched.documentType && Boolean(errors.documentType)}
                                    helperText={touched.documentType && errors.documentType}
                                />
                            </FormElement>
                            <FormElement>
                                <SelectOption
                                    isLoading={loading}
                                    data={languages.map(dt => ({ key: dt, label: t(`languages.${dt}`) }))}
                                    name="lang"
                                    label={t("Language")}
                                    value={values.lang}
                                    onChange={handleChange}
                                    error={touched.lang && Boolean(errors.lang)}
                                    helperText={touched.lang && errors.lang}
                                />
                            </FormElement>
                            <FormElement>
                                <DatePicker
                                    name="createdOn"
                                    format="YYYY-MM-DD"
                                    label={t("Document creation date")}
                                    value={values.createdOn ? moment(values.createdOn) : null}
                                    onChange={e => setFieldValue("createdOn", e)}
                                />
                            </FormElement>
                            <FormElement>
                                <TextField
                                    label={t("Document number")}
                                    name="documentNumber"
                                    value={values.documentNumber}
                                    onChange={handleChange}
                                    error={touched.documentNumber && Boolean(errors.documentNumber)}
                                    helperText={touched.documentNumber && errors.documentNumber}
                                />
                            </FormElement>
                            <FormElement>
                                <TextField
                                    label={t("Document author")}
                                    name="author"
                                    value={values.author}
                                    onChange={handleChange}
                                    error={touched.author && Boolean(errors.author)}
                                    helperText={touched.author && errors.author}
                                />
                            </FormElement>
                            <FormElement>
                                <TextField
                                    label={t("Addressed to")}
                                    name="addressedTo"
                                    value={values.addressedTo}
                                    onChange={handleChange}
                                    error={touched.addressedTo && Boolean(errors.addressedTo)}
                                    helperText={touched.addressedTo && errors.addressedTo}
                                />
                            </FormElement>
                            <FormElement>
                                <TextField
                                    label={t("Origin")}
                                    name="origin"
                                    value={values.origin}
                                    onChange={handleChange}
                                    error={touched.origin && Boolean(errors.origin)}
                                    helperText={touched.origin && errors.origin}
                                />
                            </FormElement>
                            <FormElement>
                                <TextField
                                    label={t("Archive")}
                                    name="archive"
                                    value={values.archive}
                                    onChange={handleChange}
                                    error={touched.archive && Boolean(errors.archive)}
                                    helperText={touched.archive && errors.archive}
                                />
                            </FormElement>
                            <FormElement>
                                <TextField
                                    label={t("Signature")}
                                    name="signatureMark"
                                    value={values.signatureMark}
                                    onChange={handleChange}
                                    error={touched.signatureMark && Boolean(errors.signatureMark)}
                                    helperText={touched.signatureMark && errors.signatureMark}
                                />
                            </FormElement>
                            <FormElement>
                                <TextField
                                    label={t("Topic")}
                                    name="topic"
                                    value={values.topic}
                                    onChange={handleChange}
                                    error={touched.topic && Boolean(errors.topic)}
                                    helperText={touched.topic && errors.topic}
                                />
                            </FormElement>
                            <FormElement>
                                <TextField
                                    label={t("Abstract")}
                                    name="abstract"
                                    multiline
                                    value={values.abstract}
                                    onChange={handleChange}
                                    error={touched.abstract && Boolean(errors.abstract)}
                                    helperText={touched.abstract && errors.abstract}
                                />
                            </FormElement>
                            <FormElement>
                                <TextField
                                    label={t("Comments")}
                                    name="comments"
                                    multiline
                                    value={values.comments}
                                    onChange={handleChange}
                                    error={touched.comments && Boolean(errors.comments)}
                                    helperText={touched.comments && errors.comments}
                                />
                            </FormElement>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container direction="column">
                            <FormElement>
                                <ItemsChipSetFormInput itemsValue={values.keywords}
                                    itemsName="keywords"
                                    entryFieldName="keyword"
                                    noItemsLabel={t("No keywords")}
                                    entryLabel={t("Keywords")}
                                    entryValue={values.keyword}
                                    formik={formik} />
                            </FormElement>
                            <FormElement>
                                <ItemsChipSetFormInput itemsValue={values.importantPersonalities}
                                    itemsName="importantPersonalities"
                                    entryFieldName="importantPersonality"
                                    noItemsLabel={t("No personalities")}
                                    entryLabel={t("Important personalities")}
                                    entryValue={values.importantPersonality}
                                    formik={formik} />
                            </FormElement>
                            {!values.editMode && <FormElement>
                                <FilesDropZone
                                    key={uploadKey} // needed because of: https://github.com/Yuvaleros/material-ui-dropzone/issues/9
                                    headerText={t("Files")}
                                    maxFileSize={5000000000}
                                    maxFilesCount={1000}
                                    previewText={t("Upload preview")}
                                    dropzoneTitle={t("Dropzone title")}
                                    files={values.pageImageFiles}
                                    onFileRemoved={removed => {
                                        setTouched(values.pageImageFiles)
                                        const fileRemovalFunction = removeFileFunctionWith(formik);
                                        fileRemovalFunction(removed)
                                    }}
                                    onSelectedFilesChanged={filesToAdd => {
                                        setTouched(values.pageImageFiles)
                                        const filesAddingFunction = addFilesFunctionWith(formik)
                                        filesAddingFunction(filesToAdd, tValidation("Files not allowed"))
                                    }} />
                            </FormElement>}
                            <FormElement>
                                {errors.pageImageFiles && touched.pageImageFiles &&
                                    <Chip
                                        icon={<PriorityHighIcon />}
                                        label={errors.pageImageFiles}
                                        color="error"
                                    />
                                }
                            </FormElement>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        label={t('Add Atrocity Data?')}
                        control={<Checkbox name={'addOptionalMetadata'}
                            checked={values.addAdditionalMetadata}
                            onChange={(e) => {
                                setFieldValue('addAdditionalMetadata', e.target.checked)
                            }}
                        />}>
                    </FormControlLabel>
                </Grid>
                {values.addAdditionalMetadata && (
                    <Grid container spacing={isMobile ? 2 : 3}>
                        <Grid item xs={12} sm={6}>
                            <Grid container direction="column">
                                <FormElement>
                                    <TextField
                                        label={t("Concentration Camps")}
                                        name="concentrationCamps"
                                        value={values.concentrationCamps ?? ''}
                                        onChange={handleChange}
                                        error={touched.concentrationCamps && Boolean(errors.concentrationCamps)}
                                        helperText={touched.concentrationCamps && errors.concentrationCamps}
                                    />
                                </FormElement>
                                <FormElement>
                                    <TextField
                                        label={t("Atrocities Number")}
                                        name="atrocitiesNumber"
                                        value={values.atrocitiesNumber ?? ''}
                                        onChange={(e) => {
                                            const regex = /^[0-9\b]+$/;
                                            if (e.target.value === "" || regex.test(e.target.value)) {
                                                handleChange(e);
                                            }
                                        }}
                                        error={touched.atrocitiesNumber && Boolean(errors.atrocitiesNumber)}
                                        helperText={touched.atrocitiesNumber && errors.atrocitiesNumber}
                                    />
                                </FormElement>
                                <FormElement>
                                    <MultiSelectOption
                                        isLoading={loading}
                                        data={perpetrators.map(p => ({ key: p, label: t(`perpetrators.${p}`) }))}
                                        name="atrocitiesPerpetrators"
                                        label={t("Atrocities Perpetrators")}
                                        value={values.atrocitiesPerpetrators ?? []}
                                        onChange={handleChange}
                                        error={touched.atrocitiesPerpetrators && Boolean(errors.atrocitiesPerpetrators)}
                                        helperText={touched.atrocitiesPerpetrators && errors.atrocitiesPerpetrators}
                                    />
                                </FormElement>
                                <FormElement>
                                    <MultiSelectOption
                                        label={t("Atrocities Victims")}
                                        isLoading={loading}
                                        data={victims.map(v => ({ key: v, label: t(`victims.${v}`) }))}
                                        name="atrocitiesVictims"
                                        value={values.atrocitiesVictims ?? []}
                                        onChange={handleChange}
                                        error={touched.atrocitiesVictims && Boolean(errors.atrocitiesVictims)}
                                        helperText={touched.atrocitiesVictims && errors.atrocitiesVictims}
                                    />
                                </FormElement>
                                <FormElement>
                                    <SelectOption
                                        isLoading={loading}
                                        data={atrocities.map(a => ({ key: a, label: t(`atrocities.${a}`) }))}
                                        name="atrocitiesTypology"
                                        label={t("Atrocities Typology")}
                                        value={values.atrocitiesTypology ?? ''}
                                        onChange={handleChange}
                                        error={touched.atrocitiesTypology && Boolean(errors.atrocitiesTypology)}
                                        helperText={touched.atrocitiesTypology && errors.atrocitiesTypology}
                                    />
                                </FormElement>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container direction="column">
                                {values.editMode && <FormElement>
                                    <PosTypography variant="subtitle2" component="p">
                                        {`${t("Current location")} ${values.locationOfAtrocities}`}
                                    </PosTypography>
                                    <PosTypography variant="body2" component="p">
                                        {t("Check current location")}&nbsp;
                                        <GMapsLink location={values.locationOfAtrocities} />
                                    </PosTypography>
                                </FormElement>}
                                <FormElement>
                                    <ReactOsmGeocoding
                                        placeholder={t('Atrocities Location')}
                                        callback={data => {
                                            setFieldValue("locationOfAtrocities", `${data.lat},${data.lon}`)
                                            setFullLocationData(data)
                                        }}
                                        city=""
                                        acceptLanguage=""
                                        countrycodes=""
                                    />
                                    {fullLocationData && <Card variant="outlined">
                                        <CardContent>
                                            <TitleTypography color="textSecondary">
                                                {t("Selected Address")}
                                            </TitleTypography>
                                            <Typography variant="body2" component="p">
                                                {fullLocationData.display_name}
                                            </Typography>
                                            <br />
                                            <TitleTypography color="textSecondary">
                                                {t("Geolocation")}
                                            </TitleTypography>
                                            <Typography variant="body2" component="p">
                                                {`${t("Latitude")}: ${fullLocationData.lat}`}&nbsp;
                                                {`${t("Longitude")}: ${fullLocationData.lon}`}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    }
                                    {touched.locationOfAtrocities && Boolean(errors.locationOfAtrocities) &&
                                        <Alert severity="error">{t('Atrocities location required')}</Alert>
                                    }
                                </FormElement>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12}>
                    {isSubmitting && <div><DelayInfo title={t('Document Uploading')} /></div>}
                    {!isSubmitting &&
                        <UploadFailedDialog open={showUploadError} okClicked={() => setShowUploadError(false)} />}
                    <br />
                    <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
                        {t("Submit")}
                    </Button>&nbsp;&nbsp;&nbsp;
                    {values.editMode &&
                        <Button color="primary" variant="contained" disabled={isSubmitting}
                            onClick={() => navigate(`/document/${doc.requestId}`)}>
                            {t("Cancel")}
                        </Button>}
                </Grid>
            </form>
        </FormContainer>
    );
};

export default UploadForm;
