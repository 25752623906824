import React from 'react';
import {useMinioLinks} from "../../../app/minio/hooks";
import {ErrorRounded} from "@mui/icons-material";
import FormElementLoading from "../FormElementLoading";
import PropTypes from "prop-types";

const Error = ({cause}) => (
    <div>
        <ErrorRounded/>{cause}
    </div>
);

const MinioDownloadLinksProvider = ({render, requestId}) => {
    const {minioLinks, error, tokenPresent} = useMinioLinks(requestId);

    return (
        <>
            { error && <Error cause={error} /> }
            { !error && !minioLinks && tokenPresent && <FormElementLoading /> }
            { minioLinks && render(minioLinks) }
        </>
    )
};

MinioDownloadLinksProvider.propTypes = {
    render: PropTypes.func.isRequired,
    requestId: PropTypes.string.isRequired
}

export default MinioDownloadLinksProvider;
