import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useState } from "react";
import config from "../config";
import { bearerAuthorizationHeader } from "../../auth/util";

const minioPresignedLinkState = (minioLinks, error, token) => ({ minioLinks, error, tokenPresent: !!token });

const buildPresignedLinkApiUrl = (host, requestId) => `${host}/document/download-urls/${requestId}`

export function useMinioLinks(requestId) {
    const { user } = useAuth();
    const token = user?.access_token;
    const [minioLinks, setMinioLinks] = useState(minioPresignedLinkState(null, null, token));

    useEffect(() => {
        if (token) {
            const { apiGateway: { host } } = config;
            const url = buildPresignedLinkApiUrl(host, requestId)
            fetch(url, bearerAuthorizationHeader(token))
                .then(response => response.json())
                .then(links => setMinioLinks(minioPresignedLinkState(links, null, token)))
                .catch(e => setMinioLinks(minioPresignedLinkState(null, e, token)));

        } else {
            setMinioLinks(minioPresignedLinkState(null, null, null));
        }
    }, [token, requestId]);

    return minioLinks;
}