import React from 'react';
import {Box, Grid, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import PropTypes from "prop-types";

const StyledBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const StyledFieldset = styled('fieldset')(({ theme }) => ({
    borderRadius: '5px'
}));

const Panel = ({children, title}) => {
    return (
        <Grid item xs={12}>
            <StyledFieldset>
                <legend>
                    <Typography variant="body1" gutterBottom>{title}</Typography>
                </legend>
                <StyledBox>
                    {children}
                </StyledBox>
            </StyledFieldset>
        </Grid>
    );
};

Panel.propTypes = {
    title: PropTypes.string.isRequired
}

export default Panel;
