import React from 'react';
import {DropzoneAreaBase} from "mui-file-dropzone";
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {Card, CardContent, CardHeader} from "@mui/material";
import {Description} from "@mui/icons-material";

const StyledCard = styled(Card)(() => ({
    '& .previewChip': {
        minWidth: 160,
        maxWidth: 210
    },
    '& .header': {
        textAlign: "left"
    }
}));

const FilesDropZone = ({
                           previewText,
                           files,
                           headerText,
                           onSelectedFilesChanged,
                           onFileRemoved,
                           dropzoneTitle,
                           maxFilesCount,
                           maxFileSize
                       }) => {
    return (
        <StyledCard>
            <CardHeader
                className="header"
                avatar={<Description/>}
                title={headerText}
            />
            <CardContent>
                <DropzoneAreaBase
                    fileObjects={files}
                    onAdd={onSelectedFilesChanged}
                    onDelete={onFileRemoved}
                    showPreviews={true}
                    showAlerts={['error']}
                    showPreviewsInDropzone={false}
                    dropzoneText={dropzoneTitle}
                    useChipsForPreview
                    filesLimit={maxFilesCount}
                    maxFileSize={maxFileSize}
                    previewGridProps={{container: {spacing: 1, direction: 'row'}}}
                    previewChipProps={{classes: {root: 'previewChip'}}}
                    previewText={previewText}/>
            </CardContent>
        </StyledCard>
    );
};

FilesDropZone.propTypes = {
    previewText: PropTypes.string.isRequired,
    headerText: PropTypes.string.isRequired,
    onSelectedFilesChanged: PropTypes.func.isRequired,
    onFileRemoved: PropTypes.func.isRequired,
    dropzoneTitle: PropTypes.string.isRequired,
    maxFilesCount: PropTypes.number,
    maxFileSize: PropTypes.number,
}

FilesDropZone.defaultProps = {
    maxFilesCount: 3,
    maxFileSize: 1000000
}

export default FilesDropZone;
