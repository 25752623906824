import React from "react";
import Panel from "../container/Panel";
import {useTranslation} from "react-i18next";
import {Box, Grid, Table, TableBody, TableCell, TableHead} from "@mui/material";
import {DataSearch, DateRange, MultiDropdownList, ReactiveBase, ReactiveList} from "@appbaseio/reactivesearch";
import config from "../../app/config";
import {styled} from "@mui/material/styles";
import MultiOptionSelect from "../components/search/MultiOptionSelect";
import DocumentInfo from "../components/search/DocumentInfo";

const StyledSearchInput = styled('div')({
    '& .search-input': {
        borderRadius: "8px"
    }
});

const StyledAlignLeft = styled('div')({
    '& li': {
        textAlign: "left"
    }
});

const Search = () => {
    const {t} = useTranslation("search");
    const {t: tForm} = useTranslation("uploadForm");
    const {elasticsearch: {username, password, url}} = config;

    return (
        <Panel title={t("Search")}>
            <ReactiveBase
                url={url}
                app="elasticsearch"
                credentials={`${username}:${password}`}
                endpoint={{
                    url: url,
                    headers: {},
                    method: 'POST'
                }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <Box>
                            <h3>Filters</h3>
                            <MultiOptionSelect
                                field="documentType.keyword"
                                id="documentTypeFilter"
                                title={t("Doc Type")}
                                textProvider={label => tForm(`documentTypes.${label}`)}/>
                            <br/>
                            <DateRange
                                title="Creation Date"
                                placeholder={{
                                    start: t("Start Date"),
                                    end: t("End Date"),
                                }}
                                numberOfMonths={1}
                                dataField="createdOn"
                                componentId="dateFilter"/>
                            <br/>
                            <MultiOptionSelect
                                title={t("Language")}
                                field="lang.keyword"
                                id="languageFilter"
                                textProvider={label => tForm(`languages.${label}`)}/>
                            <br/>
                            <StyledAlignLeft>
                                <MultiDropdownList
                                    title={t("Author")}
                                    placeholder={''}
                                    showCount={false}
                                    dataField='author.keyword'
                                    componentId='authorFilter' />
                            </StyledAlignLeft>
                            <br/>
                            <StyledAlignLeft>
                                <MultiDropdownList
                                    title={t('Origin')}
                                    placeholder={''}
                                    showCount={false}
                                    dataField='origin.keyword'
                                    componentId='originFilter'/>
                            </StyledAlignLeft>
                            <br/>
                            <StyledAlignLeft>
                                <MultiDropdownList
                                    title={t('Archive')}
                                    placeholder={''}
                                    showCount={false}
                                    dataField='archive.keyword'
                                    componentId='archiveFilter'/>
                            </StyledAlignLeft>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Box>
                            <h3>Search and Results</h3>
                            <StyledSearchInput>
                                <DataSearch
                                    componentId="searchbox"
                                    dataField={[
                                        "title", "author", "addressedTo", "text", "documentNumber",
                                        "origin", "archive", "signatureMark", "keywords", "topic",
                                        "abstract", "importantPersonalities", "comments", "requestId"
                                    ]}
                                    queryFormat="or"
                                />
                            </StyledSearchInput>
                            <ReactiveList
                                componentId="result"
                                title="Results"
                                dataField="model"
                                from={0}
                                size={20}
                                pagination={true}
                                react={{
                                    and: [
                                        "searchbox",
                                        "documentTypeFilter",
                                        "dateFilter",
                                        "languageFilter",
                                        "authorFilter",
                                        "originFilter",
                                        "archiveFilter"
                                    ],
                                }}
                                render={({data}) => (
                                    <ReactiveList.ResultCardsWrapper>
                                        {data && data.length > 0 ? (
                                            <Table size="small">
                                                <TableHead>
                                                    <TableCell>{tForm("Title")}</TableCell>
                                                    <TableCell>{tForm("Document Type")}</TableCell>
                                                    <TableCell>{tForm("Document author")}</TableCell>
                                                    <TableCell>{t("Language")}</TableCell>
                                                </TableHead>
                                                <TableBody>
                                                    {data.map(item => (<DocumentInfo key={item._id} doc={item} />))}
                                                </TableBody>
                                            </Table>
                                        ) : null}
                                    </ReactiveList.ResultCardsWrapper>
                                )}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </ReactiveBase>
        </Panel>
    );
};

export default Search;
