import config from "../app/config";

export const oidcConfig = {
  authority: `${config.keyCloak.host}/realms/${config.keyCloak.realm}`,
  client_id: config.keyCloak.client,
  scope: 'openid profile email',
  loadUserInfo: true,
  monitorSession: true,
  revokeTokensOnSignout: true,
  redirect_uri: `${window.location.origin}${window.location.pathname}`,
  post_logout_redirect_uri: window.location.origin,
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  }
};