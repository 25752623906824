import React from 'react';
import {DataGrid} from "@mui/x-data-grid";
import PropTypes from "prop-types";
import {CircularProgress} from "@mui/material";
import {Done, Error, Help, ReportProblem} from "@mui/icons-material";
import moment from "moment";

const statusIcons = {
    "NOT_EXIST": <Help/>,
    "RUNNING": <CircularProgress size={20}/>,
    "DONE": <Done/>,
    "ERROR": <Error color="error"/>
}

const UTC_OFFSET = moment().local().utcOffset();

const StatusCell = props => {
    return statusIcons[props.value] || <ReportProblem/>;
}

const timestampFormatter = fp => {
    return fp.value? moment(fp.value).add(UTC_OFFSET, "m").format("DD/MM/YYYY - HH:mm:ss") : ""
}

const RequestStatusDataGrid = ({data, onPageChange, pageSize, totalElements, onRowClick, page}) => {
    return (
        <DataGrid paginationMode="server"
                  onRowClick={(params) => onRowClick(params.row)}
                  hideFooterSelectedRowCount
                  density="compact"
                  autoHeight
                  paginationModel={{
                    pageSize,
                    page
                  }}
                  pageSizeOptions={[pageSize]}
                  rowCount={totalElements}
                  columns={[
                      {field: "uuid", headerName: "Request Id", width: 300},
                      {field: "startedAt", headerName: "Started At", width: 200, valueFormatter: timestampFormatter},
                      {field: "finishedAt", headerName: "Finished At", width: 200, valueFormatter: timestampFormatter},
                      {field: "requestStatusValue", headerName: "Status", renderCell: gcp => <StatusCell {...gcp} />}
                  ]}
                  rows={data}
                  onPaginationModelChange={(model) => onPageChange(model.page)}
                  getRowId={row => row.uuid}/>
    );
}

RequestStatusDataGrid.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    onPageChange: PropTypes.func.isRequired,
    totalElements: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    onRowClick: PropTypes.func
};

RequestStatusDataGrid.defaultProps = {
    onRowClick: () => {}
}

export default RequestStatusDataGrid;
